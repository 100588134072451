<template>
    <div class="min-h-1100px flex flex-col justify-between pt-6 px-8 " v-if="invoice">
		<div class="grid grid-flow-row gap-8">
			<div class="flex justify-between">
				<div>
					<ul v-if="artistAddress">
						<li>{{ artistName }}</li>
						<li v-if="artistAddress.address_line_1">{{ artistAddress.address_line_1 }}</li>
						<li v-if="artistAddress.address_line_2">{{ artistAddress.address_line_2 }}</li>
						<li>{{ artistAddress.town }}</li>
						<li>{{ artistAddress.county }}</li>
						<li>{{ artistAddress.post_code }}</li>
						<li v-if="artistEmail">{{ artistEmail }}</li>
					</ul>
					<ul v-else>
						<li>{{ artistName }}</li>
						<li>Billing address not supplied</li>
					</ul>
				</div>
				<div class="grid grid-flow-row gap-4"  v-if="invoice">
					<span class="text-4xl">Invoice</span>
					<div>
						<div>Invoice: GP-{{ invoice.invoice_number }}</div>
						<div v-if="invoice.signed_off_at">Date issued: {{signoffFormat(invoice.signed_off_at)}}</div>
						<div v-if="artistUtrNumber">UTR Number: {{ artistUtrNumber }}</div>
						<div v-if="artistVatNumber">VAT Number: {{ artistVatNumber }}</div> 

					</div>

				</div>
			</div>
			<div class="grid grid-flow-row">
				<div class="font-bold uppercase text-sm">
					Invoice to
				</div>
					<ul v-if="venueAddress"> 
						<li>{{ venueAddress.address_line_1 }}</li>
						<li v-if="venueAddress.address_line_2">{{ venueAddress.address_line_2 }}</li>
						<li>{{ venueAddress.town }}</li>
						<li>{{ venueAddress.county }}</li>
						<li>{{ venueAddress.post_code }}</li>
						
					</ul>
			</div>
      <div v-if="invoice.bids.length">

        <div class="uppercase font-bold text-sm">Item description</div>
        <div v-for="(bid, index) in invoice.bids" :key="bid.id">
          <InvoiceItemDescription :invoice-currency-display-name="invoice.currency_symbol" :invoice-item="bid" :index="index" :fee-or-total="'Fee'"/>
        </div>
      </div>

			<div class="flex justify-between border-t  border-black">
				<div>Total payable</div>
				<div>{{invoice.currency_symbol}}{{ calculateTotal() }} </div>
			</div>

      <div class="grid grid-flow-row w-full gap-4">
        <div>
          <h2 class="font-bold">Amounts payable to:</h2>
          {{artistName}}
          <br/>
          Sort code: {{artistSortCode}}
          <br/>
          Account: {{artistAccountNumber}}
          <br>
          <div v-if="artistIbanNumber">
            IBAN number: {{artistIbanNumber}}
          </div>
        </div>
      </div>
		</div> 
		<div v-if="invoice.payment_terms" class="text-left  flex flex-col justify-between items-start mt-4">
			<p><strong>Venue payment terms</strong></p>
			<p v-html="invoice.payment_terms"></p>
		</div>
		<div class="text-center  flex flex-row justify-between items-center mt-20">
			
			<a href="https://gigpig.uk">
				<img src="@/assets/images/poweredby_pdf.jpg" alt="Powered by Gig Pig" class="h-12 w-20"/>
			</a>
		</div>
	</div>
</template>
  
<script>
	import format from '../../utils/format-date-default' 
	import formatDate from '../../utils/format-date'
  import InvoiceItemDescription from "@/components/invoice/InvoiceItemDescription.vue";

	const { DateTime } = require("luxon");

	export default
	{
		name: "InvoiceContents",
    components: {InvoiceItemDescription},
    props:{
      invoice: Object,
    }, 
		methods: {
			format,
			formatDate,
			signoffFormat(date){
		
			if (date == null)
			return '';
		
			return DateTime.fromFormat(date, "yyyy-MM-dd HH:mm:ss").toFormat("dd/MM/yyyy")
			},
      calculateTotal(){
        let total = 0;
        const $this = this

        this.invoice.bids.forEach(function(bid){
          let fee = parseFloat(bid.fee)
          total += bid.vat_number ? $this.calculateVAT(fee, bid.vat_rate) + fee : fee;
        })

        return total.toFixed(2);
      },
			calculateVAT(fee, vat_rate){
				return fee * (vat_rate / 100)
			}
		},
		computed: {
			artistEmail() {
				return this.invoice.artist.email ? this.invoice.artist.email : false
			},
      artistName() {
        if(this.invoice.artist_name) {
          return this.invoice.artist_name
        } else if(this.invoice.artist !== null && this.invoice.artist.name) {
          return this.invoice.artist.name
        }

        return ''
      },
      artistAddress() {
        if(this.invoice.artist_billing_address) {
          return this.invoice.artist_billing_address
        } else if(this.invoice.artist !== null && this.invoice.artist.address) {
          return this.invoice.artist.address
        }

        return ''
      },
      artistAccountNumber() {
        if(this.invoice.account_number) {
          return this.invoice.account_number
        } else if(this.invoice.artist !== null && this.invoice.artist.account_number) {
          return this.invoice.artist.account_number
        }

        return ''
      },
      artistSortCode() {
        if(this.invoice.sort_code) {
          return this.invoice.sort_code
        } else if(this.invoice.artist !== null && this.invoice.artist.sort_code) {
          return this.invoice.artist.sort_code
        }

        return ''
      },
      artistUtrNumber() {
        if(this.invoice.utr_number) {
          return this.invoice.utr_number
        } else if(this.invoice.artist !== null && this.invoice.artist.utr_number) {
          return this.invoice.artist.utr_number
        }

        return ''
      },
      artistVatNumber() {
        if(this.invoice.vat_number) {
          return this.invoice.vat_number
        } else if(this.invoice.artist !== null && this.invoice.artist.vat_number) {
          return this.invoice.artist.vat_number
        }

        return ''
      },
      artistIbanNumber() {
        if(this.invoice.iban_number) {
          return this.invoice.iban_number
        } else if(this.invoice.artist !== null && this.invoice.artist.iban_number) {
          return this.invoice.artist.iban_number
        }

        return ''
      },
      venueAddress() {
        if(this.invoice.venue_billing_address) {
          return this.invoice.venue_billing_address
        } else if(this.invoice.venue !== null && this.invoice.venue.address) {
          return this.invoice.venue.address
        }

        return ''
      },
		}
	}
</script>