
<template>
    <div v-show="showItem" id="tooltip" class="absolute z-40 drop-shadow-xl inline-block w-96 px-3 py-2 text-sm font-medium text-black bg-gradient-to-r from-white to-gray-100  rounded-lg shadow-sm tooltip dark:bg-gray-700">
        <div v-if="showItem">
            <div v-if="this.item.name" class="text-base leading-6 text-gray-900 justify-between flex p-1">
                <div>
                  <h3 v-if="this.item.name.length < 28">{{ this.item.name }}</h3>
                  <h3 v-else>{{ this.item.name.substring(0,21)+"..." }}</h3>
                  <h3 class="text-sm" v-if="this.item.venue.name && this.isArtistView">{{ this.item.venue.name }}</h3>
                </div>

                <p v-if="this.item.status"><gig-status :gig="getStatus" class="self-center" /></p>
            </div>
            <h3 v-if="this.item.start && this.item.end" class="pl-1 mt-2 text-indigo-500">{{ DateTime.fromISO(this.item.start).toUTC().toFormat('cccc DDD HH:mm')}} - {{ getDateHour(this.item.end)}}333</h3>

            <div v-if="!this.isArtistView && this.item.custom_tags.length > 0" class="my-3 space-x-3 flex items-center">
                <div class="flex items-center justify-center px-3 py-1 rounded-3xl space-x-2 bg-black">
                    <component :is="icons[firstCustomTagIconName(item.custom_tags[0])]" class="w-4 h-4" :style="{ color: firstCustomTagIconColour(item.custom_tags[0]) }"/>
                    <span class="text-white text-xs">{{ firstCustomTagName(item.custom_tags[0]) }}</span>
                </div>
                <div>
                    <span class="relative inline-flex flex-col items-center group" v-if="this.item.custom_tags.length > 0">
                        <span v-if="this.item.custom_tags.length > 1"
                        class="text-sm font-medium underline underline-offset-2 cursor-pointer"
                        >+{{ item.custom_tags.length - 1 }} more</span>
                        <div class="absolute z-10 bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
                        <span class="p-4 bg-black rounded-sm shadow-lg w-48">
                            <ul class="list-none">
                            <li v-for="tag in item.custom_tags" :key="tag.id" class="text-white flex space-x-4 items-center space-y-1">
                                <component :is="icons[tag.icon]" class="w-5 h-5" :style="{ color: tag.icon_colour }"/>
                                <span>{{ tag.name }}</span>
                            </li>
                            </ul>
                        </span>
                        <div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
                        </div>
                    </span>
                </div>
            </div>

            <div class="relative flex py-3 items-center">
                <div class="flex-grow border-t border-gray-400"></div>
                <div class="flex-grow border-t border-gray-400"></div>
            </div>

            <!-- Venue -->
            <template v-if="!this.isArtistView && this.item.bookings">

                <h2 v-show="displayStaged && item.bookings.length > 0" class="font-bold">Pending Artists</h2>
                <h2 v-show="!displayStaged && item.bookings.length > 0" class="font-bold">Booked Artists</h2>

                <div v-for="(booking, index) in this.item.bookings"  :key="'booking' + index" class="mt-4">
                    <div v-if="booking.artist">
                        <h3 class="text-base leading-6 text-gray-900 flex p-1 items-center justify-between">
                            <div class="flex">
                                <img v-if="booking.artist.image && booking.artist.image.url !== null" :src="booking.artist.image.url" class="w-6 h-6 object-cover rounded-full object-center border-2 border-green-400" />
                                <img v-else :src="require('@/assets/images/placeholder.svg')" class="w-6 h-6 object-cover rounded-full object-center border-2 border-green-400" />
                                <span v-if="booking.artist.name.length < 27" class="ml-4">{{ booking.artist.name }}</span>
                                <span v-else class="ml-4">{{ booking.artist.name.substring(0,23)+"..." }}</span>
                            </div>
                            <span v-if="booking.fee" class="text-gp_pink-default-500 text-sm items-end">{{ item.currency.symbol }}{{ booking.fee }} <span v-if="booking.artist.vat_number">+ VAT</span></span>
                        </h3>
                        <span v-if="booking.artist.tier.name" class="text-xs items-start ml-11 text-gray-500">{{ booking.artist.tier.name }}111</span>
                    </div>
                </div>
            </template>

            <!-- Artist -->
            <template v-if="this.isArtistView && this.artistBid && this.artistBid.accepted && this.artistBid.artist_final_accepted">
                <div class="rounded p-2 bg-none">
                    <div class="text-base leading-6 text-gray-900 flex p-1 items-center justify-between">
                        <div class="flex">
                            <img v-if="this.bookedArtist" :src="this.bookedArtist" class="w-6 h-6 object-cover rounded-full object-center border-2 border-green-400" />
                            <img v-else :src="require('@/assets/images/placeholder.svg')" class="w-6 h-6 object-cover rounded-full object-center border-2 border-green-400" />
                            <span v-if="this.artistBid.artist.name.length < 27" class="ml-4">{{ this.artistBid.artist.name }}ff</span>
                            <span v-else class="ml-4">{{ this.artistBid.artist.name.substring(0,23)+"..." }}ggg</span>
                        </div>
                        <span v-if="this.artistBid.fee" class="text-gp_pink-default-500 text-sm items-end">{{ this.artistBid.gig.currency.symbol }}{{ this.artistBid.fee }} hhh<span v-if="this.artistBid.vat_rate">+ VAT</span></span>
                    </div>
                    <span v-if="this.artistBid.artist.tier.name" class="text-xs items-start ml-11 text-gray-500">{{ this.artistBid.artist.tier.name }}jjj</span>
                </div>
        
                <div v-show="this.item.bookings.length > 1" class="mt-4">
                    <div class="mt-3 flex items-center text-gray-800">
                        <UserIcon class="w-4 h-4"/>
                        <p class="ml-2 text-sm">Other Bookings</p>
                    </div>

                    <div class="mt-6">
                        <div v-for="(booking, index) in this.item.bookings"  :key="'booking' + index" class="mt-4">
                            <div class="text-base leading-6 text-gray-900 flex p-1 items-center justify-between">
                                <div v-show="booking.artist.id !== artistBid.artist.id" class="flex">
                                    <img v-if="booking.artist.image && booking.artist.image.url !== null" :src="booking.artist.image.url" class="w-6 h-6 object-cover rounded-full object-center border-2 border-green-400" />
                                    <img v-else :src="require('@/assets/images/placeholder.svg')" class="w-6 h-6 object-cover rounded-full object-center border-2 border-green-400" />
                                    <span v-if="booking.artist.name.length < 27" class="ml-4">{{ booking.artist.name }}</span>
                                    <span v-else class="ml-4">{{ booking.artist.name.substring(0,23)+"..." }}</span>
                                </div>
                            </div>
                            <span v-show="booking.artist.tier.name && booking.artist.id !== artistBid.artist.id" class="text-xs items-start ml-11 text-gray-500">{{ booking.artist.tier.name }}222</span>
                        </div>
                    </div>
                </div>

            </template>
        </div>


    </div>
</template>

<script>
import GigStatus from '../iconsets/GigStatus.vue'
import { getStatusFromType as getGigStatus } from '@/state/models/gigStatus'
import { getStatusFromType as getBidStatus } from '@/state/models/bidStatus'
import { apiComputed, apiMethods } from '@/state/helpers';
import {DateTime} from 'luxon';
import normalizeDate from '../../utils/normalizeDate.js';
import { UserIcon } from "@vue-hero-icons/outline"
import  * as icons from "@vue-hero-icons/outline" 

export default {
    name: "CalendarHover",
    components: { GigStatus, UserIcon },
    data(){
        return {
            tooltip: false,
            isArtistView: false,
            artistBid: null,
            bookedArtist: null,
            item: [],
            userType: null,
            DateTime,
            bidId: Number,
            showAsBid: {
                type: Boolean,
                default: false
            },
            displayStaged: false,
            icons: icons
        }
    },
    props:{
        viewType: Boolean,
        calendarItem: Object,
        bid: Object,
        theArtist: Object
    },
    mounted() {
        this.isArtistView = this.viewType;
        this.item = this.calendarItem;
        if (this.item) {
            if (this.item.bookings.length == 0 && this.item.staged_artists.length > 0) {
                this.displayStaged = true
                this.item.bookings = this.item.staged_artists
            }
        }
        this.artistBid = this.bid;
        this.bookedArtist = this.theArtist;
    },
    computed:{
        ...apiComputed,
        getStatus(){         
            return { status: this.isArtistView  ? getBidStatus(this.item.status) : getGigStatus(this.item.status) }
        },
        showItem() {
            return this.item !== undefined && this.tooltip
        },
    },
    methods:{
        ...apiMethods,
        firstCustomTagName(val){
            return val.name
        },
        firstCustomTagIconName(val){
            return val.icon
        },
        firstCustomTagIconColour(val){
            return val.icon_colour
        },
        getDateHour($date){
            let normalised = normalizeDate($date);
            let datetime = DateTime.fromFormat(normalised, 'yyyy/MM/dd HH:mm');
            return datetime.toFormat('HH:mm');
        },
    }
}
</script>